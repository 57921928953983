import React, { useState } from "react"

function NavLink(props) {
    const { link, activeLink, ...restProps } = props;
    return (
        <a
            {...restProps}
            className={`${
                      activeLink === link
                        ? "current"
                    : null
                    } background-primary circle`}
                    href={link}
                >
        </a>
    )
}

export default NavLink;
