import React, { useRef } from 'react';
import useScrollPosition from '../components/hooks/useScrollPosition';
import ChevronBottom from '../assets/icon-arrow-down.svg';

function Profile(props) {
    const { image, firstItemLink, profile, parentScrollPosition, handleOnScroll } = props;
    const refContainer = useRef(null);
    useScrollPosition(refContainer, parentScrollPosition, () => {
        handleOnScroll('home');
    });
    return (
        <div ref={refContainer} id="home" className="individual-headshot fadein ">
            <h2 className="highlight background-primary">{ profile.author }</h2>
            <div className="primary-border-five profile">
                <img src={profile.image}/>
            </div>
            <div className="profile-info">
                <h2 className="left-border">{ profile.title}</h2>
                <p className="left-border">{
                    profile.description
                }</p>
            </div>
            <a href={firstItemLink} className="arrow">
                <ChevronBottom />
            </a>
        </div>
    )
}

export default Profile;
