import React, { useRef, useEffect } from "react"
import { Link } from "gatsby"
import useScrollPosition from "../components/hooks/useScrollPosition"

function Item(props) {
  const {
    activeLink,
    handleOnScroll,
    parentScrollPosition,
    item: {
      node: {
        fields: { slug },
        frontmatter: { short_description, title, banner },
      },
    },
  } = props
  const refContainer = useRef(null)
  useScrollPosition(refContainer, parentScrollPosition, () => {
    handleOnScroll(title)
  })

  return (
    <div
      ref={refContainer}
      id={title}
      className={`l-content ${activeLink === title} ? current : null`}
    >
      <div>
        <div>
          <h2> {title} </h2>
          <p>{short_description}</p>
          <Link to={slug}>View the full project</Link>
        </div>
      </div>
      <div className="background-primary">
        <img src={banner}></img>
      </div>
    </div>
  )
}

export default Item
