import React, { useEffect, useState } from 'react';
import MenuSvg from '../assets/menu.svg';
import { Link } from "gatsby"

function Menu(props) {
    const { items, contentScroll: {scrollTop, clientHeight} } = props;
    const [style, setStyle] = useState({fill: 'unset'});
    function toggleStyle(ev) {
        if (!( scrollTop > clientHeight )) {
            if(style.fill === 'unset') {
                setStyle({fill: 'white'});
            } else {
                setStyle({fill: 'unset'});
            }
        }

    }
    useEffect(() => {
        if (scrollTop > clientHeight) {
            setStyle({fill: 'white'});
        } else {
            setStyle({fill: 'unset'});
        }
    }, [ scrollTop, clientHeight ]);
    return (
        <>
        <input onClick={ toggleStyle } type="checkbox" id="nav" />
        <label className="menuanim" htmlFor="nav"><MenuSvg style={style} /></label>
        <nav className="project-list">
            <ul>
                {items.map(item => (
                    <li key={item.node.fields.slug} className="nav-item">
                        <Link to={item.node.fields.slug}> {item.node.frontmatter.title} </Link>
                    </li>
                ))}
            </ul>
        </nav>
        </>
    )
}

export default Menu;
