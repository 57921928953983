import React from "react"
import { StaticQuery, graphql } from "gatsby"
import EmailIcon from "../assets/icon-envelope-closed.svg"

function SocialNav() {
  return (
    <StaticQuery
      query={graphql`
        query SocialNav {
          site {
            siteMetadata {
              email
              socialLinks {
                link
                title
                icon
              }
            }
          }
        }
      `}
      render={data => {
        const {
          site: {
              siteMetadata:
              { email, socialLinks },
          },
        } = data
        return (
          <nav className="social-nav fadein">
            {socialLinks.map(link => (
              <a
                key={link.link}
                href={link.link}
                target="_blank"
                className="icon-link nav-item"
              >
                  <div className="icon"><img src={link.icon} /> </div>
                <div className="social-text">{link.title}</div>
              </a>
            ))}
            {email ? (
              <a
                target="_blank"
                href={ `mailto:${email}` }
                className="icon-link nav-item"
              >
                <div className="icon">
                  <EmailIcon />
                </div>
                <div className="social-text">Email</div>
              </a>
            ) : null}
          </nav>
        )
      }}
    />
  )
}

export default SocialNav
