import React, { useRef, useLayoutEffect } from 'react';

function useScrollPosition(ref, parentScrollPosition, handleOnScroll) {
    useLayoutEffect(() => {
        if (ref.current) {
            if (parentScrollPosition > (ref.current.offsetTop - 100) && parentScrollPosition < ( (ref.current.offsetTop + ref.current.clientHeight) + 100)) {

                handleOnScroll();
            }
        }
    }, [ parentScrollPosition ]);
}

export default useScrollPosition;

