import React, { useRef, useState, useEffect, useLayoutEffect } from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import Item from "../components/item"
import Universe from "../assets/universe.svg"
import Menu from "../components/menu"
import SocialNav from "../components/socialNav"
import Profile from "../components/profile"
import NavLink from "../components/link"
import anime from 'animejs'

const IndexPage = ({ data }) => {
    const {
        allMarkdownRemark: { totalCount, edges },
    } = data
    const universeRef = useRef(null);
    const [selectedProject, setSelectedProject] = useState("home")
    const [contentScroll, setContentScroll] = useState(0)
    const [isLoading, setIsLoading] = useState(true);
    const contentRef = useRef();

    useLayoutEffect(() => {
        if (contentRef.current) {
            setContentScroll(contentRef.current.scrollTop);
            setSelectedProject(document.location.hash ? decodeURIComponent(document.location.hash.slice(1)) : "home");

        }
    }, []);

    useEffect(() => {
        if(universeRef.current) {
            const path = anime.path('#path1466');
            const pathTwo = anime.path('#path2')
            const target = document.querySelector('.follow');
            anime({
                targets: target,
                translateX: path('x'), // Follow the x values from the path `Object`
                translateY: path('y'), // Follow the y values from the path `Object`
                easing: 'linear',
                duration: 50000,
                loop: true
            })
            anime({
                targets: '.follow2',
                translateX: pathTwo('x'), // Follow the x values from the path `Object`
                translateY: pathTwo('y'), // Follow the y values from the path `Object`
                easing: 'linear',
                duration: 80000,
                loop: true
            })
            setIsLoading(false);
        }
    }, []);

    return (
        <>
        <SEO title="Home" />
        <div className="load-container">
            <div id="loader"></div>
        </div>

        <div className="center-padding background-primary">
            <div className="outer-border min-height ">
                <div className={isLoading ? "hide" : "fadein"} ref={universeRef} id="universe">
                    <Universe />
                </div>
                <div className="min-height wrapper primary-border-ten fadein">
                    <nav className="project-nav fadein">
                        <p> 0/{totalCount} </p>
                        <div className={"background-image-primary nav-wrap"}>
                            <NavLink
                                link={"#home"}
                                activeLink={`#${selectedProject}`}
                            />
                            {edges.map(item => (
                                <NavLink
                                    key={item.node.id}
                                    link={"#" + item.node.frontmatter.title}
                                    activeLink={`#${selectedProject}`}
                                />
                            ))}
                        </div>
                        <p>
                            {totalCount}/{totalCount}
                        </p>
                    </nav>
                    <div
                        ref={contentRef}
                        onScroll={el => {
                            setContentScroll(el.currentTarget.scrollTop)
                        }}
                        className="l-slider"
                    >
                        <div className="content-wrapper">
                            <Profile
                                handleOnScroll={setSelectedProject}
                                parentScrollPosition={contentScroll}
                                profile={data.site.siteMetadata}
                                firstItemLink={`#${edges[0].node.frontmatter.title}`}
                            />
                            {edges.map(item => (
                                <Item
                                    key={item.node.id}
                                    parentScrollPosition={contentScroll}
                                    handleOnScroll={setSelectedProject}
                                    item={item}
                                />
                            ))}
                        </div>
                    </div>
                    <Menu
                        contentScroll={contentScroll}
                        items={edges} />
                    <SocialNav />
                </div>
            </div>
        </div>
        </>
    )
}

export const query = graphql`
  query HomePageQuery {
    site {
      siteMetadata {
        description
        author
        title
        image
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "DD MMMM, YYYY")
            banner
            short_description
          }
          fields {
            slug
          }
        }
      }
    }
  }
`

export default IndexPage
